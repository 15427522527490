// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

/*User CSS*/

// Some interesting classes //
/*
text-dark-stroke
text-light-stroke 
text-truncate
font-alt
grayscale


              <div class="bg-white p-5 line-draw-animation h-100">
                <div class="line-draw-inner">
                </div>
              </div>

                  <!-- Card image popup -->
                  <a
                    class="
                      card-element-hover
                      position-absolute
                      end-0
                      top-0
                      bg-primary
                      p-3
                    "
                    href="assets/images/_robinsonconstruction/what-we-do.jpg"
                    data-glightbox
                    data-gallery="portfolio"
                    ><i class="fas fa-expand text-white"></i
                  ></a>
              
*/
/********************************************/
/*          TOMPLUMB                     		*/
/********************************************/

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600



/*----------------------------------------------------------
// **************** UNIVERSAL STYLES     **************** //
----------------------------------------------------------*/
// 0. ALL SECTIONS //
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  font-optical-sizing: auto;
  font-weight: 800;
  color:  $blue;
}
p,
li {
  font-family: $font-family-base;
  font-optical-sizing: auto;
  font-weight: 400;
  color: $dark;
  font-size: 1.05rem;
}
p {
  line-height: 1.5rem;
}
main section ul {
  // list-style: none;
  // width: 200px;
  text-indent: -20px; /* key property */
  margin-left: 20px; /* key property */
}
main section li {
  line-height: 1.2rem;
  margin-bottom: 6px;
}
.bg-white {
  background: #fff;
}
.bg-tp-white {
  background: #ffffff97;
}
.bg-black {
  background: #000;
}
.bg-dark {
  background: $dark;
}
.bg-blue-gradient {
  background: linear-gradient(180deg, #4bacdf 30%, #0099cf 90%);
}
.bg-darkgrey {
  background: $dark-grey;
}
.bg-teal {
  background: $blue-light;
}
.bg-grey {
  background: $bg-grey;
}
.text-right {
  text-align: right;
}
.border-divider {
  border-top: 1px solid #ccc;
}
.lead {
  font-size: 1rem;
}
.bold-text {
  font-weight: 800;
}
.back-top {
  background:  $blue;
}

.hm-btn{
  display: inline-block;
  min-width: 200px;
  margin-bottom: 6px;
  padding: 10px 30px;
  border: 0;
  background: $dark !important;
  color: $white !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  outline: 0;
  white-space: nowrap;
}
.hm-btn:hover {
  background: $blue !important;
  color: $white !important;
  .bi-chevron-right {
    padding-left: 6px;
  }
}
#hm-btn-action:hover {
  background: $white !important;
  color: $blue !important;
}
p.hm-about-btn {
  margin: 45px 0;
}
.btn {
  background: $dark;    
  color: $white;
  font-size: 0.88rem;
}



.container8 {
  max-width: 820px !important;
}

.container13 {
  max-width: 1300px !important;
}
@include media-breakpoint-down(xl) {
  .container, .container8, .container13 {
    padding: 0 30px;
  }
  
}
@include media-breakpoint-up(md) {
  .lead {
    font-size: 1.1rem;
  }
}
// @include media-breakpoint-up(xxl) {
//   .container {
//     max-width: 1300px;
//   }
// }
// .container {
//   max-width: 90%;
// }

// a.btn-primary:hover,
// a.btn-dark:hover {
//   color: #fff !important;
//   background:  $blue !important;
//   border-color:  $blue !important;
//   text-decoration: none !important;
// }

//****************************************************//
//              HEADER CONTENT START                  //
//****************************************************//

/*---------------------------/
// 0. HEADER SECTION        //
----------------------------*/

/* Remove box shadow on Navigation */
.navbar-floating .navbar > .container {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*---------------------------/
// 0.1 CALL BAR SECTION //
----------------------------*/
/*
.core-callbar {
  background-color:  $blue;
  padding: 0px 40px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.core-item {
  color: $white;
  font-size: 1.25rem;
  padding: 0 10px;
  margin: 0;
  a,
  a:visited,
  a:active {
    color: $white !important;
  }
  a:hover {
    color: $white !important;
    opacity: 0.7;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  i {
    margin-top: 2px;
    font-size: 1.9rem;
  }
}
@include media-breakpoint-up(sm) {
  .core-callbar {
    justify-content: flex-end;
  }
}

@include media-breakpoint-up(lg) {
  .core-callbar {
    padding: 0px 10px;
  }
}
@media (max-width: 480px) {
  .core-item {
    font-size: 1rem;
    padding: 0 8px;
    i {
      font-size: 1.4rem;
    }
  }
}
*/
/*---------------------------/
// 1. BRAND LOGO //
----------------------------*/

/* Adjust Logo padding (was 30px) */
.navbar-brand {
  padding: 10px 0;
  margin: 0 !important;
}
.navbar-brand .navbar-brand-item {
  height: 54px;
  margin: 10px;
}

/* Adjust Logo height (was 30px) */
.navbar-brand .navbar-brand-item,
header.navbar-sticky-on .navbar-brand {
  // padding: 4px 20px !important;
  .navbar-brand-item {
    height: 54px;
  }
}

// .hm-login-btn {
//   color: $yellow !important;
// }
// .hm-login-btn:hover {
//   color:  $blue !important;
// }


// @include media-breakpoint-down(sm) {
//   #nav-container {
//     max-width: 100%;
//   }
// }

// @include media-breakpoint-up(sm) {
  /* Adjust Logo height (was 30px) */
  // header.navbar-sticky-on .navbar-brand {
  //   padding: 8px 20px !important;
  //   .navbar-brand-item {
  //     height: 69px;
  //   }
  // }
// }

@include media-breakpoint-up(lg) {
  // #nav-container {
  //   max-width: 1320px;
  // }
  .navbar-brand {
    padding: 1px 0 2px;
  }
  // .navbar-brand .navbar-brand-item {
  //   height: 85px;
  //   padding: 0 !important;
  // }
}

@include media-breakpoint-up(xl) {
  #nav-container {
    max-width: 1340px !important;
  }
  .navbar-brand .navbar-brand-item {
    height: 92px;
    padding: 0 !important;
  }
}

/*---------------------------/
// 1. FULLSCAPE NAV MENU //
----------------------------*/

#nav-action-panel {
  padding: 1.5rem 1rem 1rem 1rem !important;
}
// #nav-services {
//   a:hover p {
//     color:  $blue;
//   }
// }
// .nav-service {
//   h6 {
//     font-size: 16px;
//   }
//   p {
//     font-size: 15px;
//     line-height: 20px;
//     margin: 0;
//   }
// }

.nav-projects {
  padding-left: 0rem !important;
  // img {
  //   border: 1px solid $white;
  // }
  h6 {
    font-size: 0.9rem;
    color: $dark;
  }
}

a.nav-projects:hover {
  // img {
  //   border: 1px solid $dark;
  // }
  h6 {
    color: $blue !important;
  }
}

.navbar-nav .nav-item a.nav-link {
  color: $navbar-link-color;
  text-transform: uppercase;
  font-family: $navbar-font;
  font-optical-sizing: auto;
  font-weight: $navbar-link-weight;
  font-size: $navbar-link-size;
  letter-spacing: 0.5px;
}
.navbar-nav .nav-item:hover a.nav-link {
  // font-weight: 500 !important;
  color:  $navbar-hover-color !important;
}
.navbar-expand-lg .navbar-nav .nav-item > .dropdown-menu {
  background: $dropdown-background;
}
.navbar-nav .dropdown > .dropdown-menu {
  a.dropdown-item {
    color: $dropdown-link-color !important;
    font-weight: $dropdown-link-weight;
    font-size: $dropdown-link-size;
    ;
  }
  a.dropdown-item:hover {
    color:  $dropdown-hover-color !important;
  }
  // li:hover {
  //   background: $light-orange;
  // }
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 23px;
  padding-left: 23px;
  font-size: 1rem;
  font-weight: 800;
}

// @include media-breakpoint-up(md) {
//   .navbar-nav .nav-item a.nav-link {
//     font-size: 15px;
//   }
// }
@include media-breakpoint-up(md) {
  #nav-action-panel {
    padding-left: 0 !important;
    padding-bottom: 0.5rem !important;
    h5 {
      margin-top: 0px;
      text-align: center;
    }
    p {
      margin-top: 0;
    }
    .btn {
      margin-top: -10px;
      margin-bottom: 0;
    }
    .btn:hover {
      background: $white;
      color: $dark !important;
    }
  }
}
@include media-breakpoint-up(xl) {
  #navbarCollapse {
    margin-top: 12px !important;
  }
  .navbar-nav .nav-item a.nav-link {
    font-size: $navbar-link-size;
  }
 
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 20px;
    padding-left: 20px;
  }
  // #navbar-full {
  //   text-align: right;
  // }
  .navbar-nav .nav-item:last-child .nav-link {
    padding-right: 30px;
  }
  .dropdown-fullwidth .dropdown-menu {
    max-width: 1000px;
  }
}
@include media-breakpoint-down(lg) {
  // .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
  //   border-bottom: 1px solid $blue-light !important;
  // }
  .navbar-nav .nav-item a.nav-link {
    color: $dark;
  }
  // #navbarCollapse li.nav-item {
  //   background-color: $black;
  // }
  #navbarCollapse li.nav-item:hover {
    color:  $blue;
  }
  #navbarCollapse .nav-service h6 {
    color: $black;
  }
  #navbarCollapse .nav-service:hover h6 {
    color:  $blue;
  }

  .navbar-expand-lg .navbar-nav .nav-item > .dropdown-menu {
    padding: 0 !important;
    background: $white !important;
  }
  .navbar-nav .dropdown > .dropdown-menu {
    a.dropdown-item {
      color: $dark !important;
    }
    a.dropdown-item:hover {
      color:  $blue !important;
    }
  }

}

/*---------------------------/
// 2. MOBILE NAV MENU //
----------------------------*/
.navbar { // Change the toggler
  --bs-navbar-toggler-icon-bg: url(
  "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2837, 37, 37, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}



@include media-breakpoint-down(md) {
  .navbar-nav .nav-item a.nav-link {
    color: $dark !important;

  }

  // .dropdown-menu li:hover {
  //   background: $black;
  // }
  .dropdown-menu a.dropdown-item,
  .dropdown-menu a.dropdown-item:hover {
    color: $dark !important;
  }
}
// **************** MAIN BODY CONTENT START **************** //

//****************************************************//
// 1. HERO BANNER SECTION                             //
//****************************************************//

#home-hero {
  // background-image: image-set(
  //   "../../brand/.avif" type("image/avif"),
  //   "../../brand/.jpg" type("image/jpeg")
  // );
  // background-image: image-set(
  //   "./" type("image/avif")
  //   "./" type("image/jpeg")
  // );
  background: linear-gradient(
      180deg,
  rgba(75, 172, 223, 0) 60%,
  rgba(75, 172, 223, 0.8) 100%
  ), image-set(
      "../img/tomplumb-hero.avif" type("image/avif"),
      "../img/tomplumb-hero.jpg" type("image/jpg")
      );
  // background: linear-gradient(
  //     180deg,
  //     rgba(75, 172, 223, 0) 70%,
  //     rgba(75, 172, 223, 0.6) 100%
  //   ),
  // url("../images/_tomplumb/img/tomplumb-hero.png");
  //  background: url("../images/_tomgas/img/tomgas-hero.png");
  // background-image: image-set(
  // "../img/tomgas-hero.avif" type("image/avif"),
  // "../img/tomgas-hero.jpg" type("image/jpg")
  // );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}
#home-hero-txt {
  width: 100%;
  // max-width: 410px;
  // margin: 50px;
  // padding: 0 20px;
  div.display-1 {
    // margin: 0 auto;
    color: $white;
    font-family: $font-raleway;
    font-size: 40px;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: -1px;
    text-shadow: 0px 0px 5px $dark;
    text-align: center;
    text-transform: uppercase;
  }
  h1 {
    // margin: 10px auto 0;
    margin-bottom: 22px;
    color: $white;
    font-family: $font-outfit;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    // line-height: 46px;
    text-shadow: 0px 0px 4px $dark;
  }
}


@include media-breakpoint-up(sm) {
  #home-hero-txt {
    div.display-1 {
      font-size: 50px;
    }
    h1 {
      margin-bottom: 42px;
      font-size: 30px;
    }
  }

  /*  #home-hero-txt {
    max-width: 600px;
    margin-top: 480px;
    // margin-left: 40px;
    div.display-1 {
      font-size: 49px;
      line-height: 49px;

    } 
    h1 {
      font-size: 22px;
    }
  }*/
  // #home-hero img {
  //   height: 30px;
  //   margin-bottom: 30px;
  // }
  // #home-hero-txt {
  //   margin-top: 180px;
  // }
}
@include media-breakpoint-up(md) {
  #home-hero-txt {
    div.display-1 {
      font-size: 60px;
    }
    h1 {
      font-size: 40px;
    }
  }
/*  #home-hero-txt {
    max-width: 700px;
    // margin-top: 200px;
    div.display-1 {
      font-size: 68px;
      line-height: 68px;
      // max-width: 300px;
    } 
    h1 {
      font-size: 26px;
    }
  }*/
}

// @include media-breakpoint-up(lg) {
// }

@include media-breakpoint-up(xl) {
  #home-hero-txt h1 {
    font-size: 60px;
    margin-bottom: 42px;
  }
}

/*@include media-breakpoint-up(xxl) {
  // #home-hero img {
  //   height: 75px;
  //   margin-bottom: 50px;
  // }
  // #home-hero-txt {
  //   div.display-1 {
  //     font-size: 60px;
  //   }
  //   h1 {
  //     font-size: 60px;
  //     margin-bottom: 42px;
  //   }
  // }
  // .h-lg-830 {
  //   height: 830px !important;
  // }
}*/

//****************************************************//
// 2. INTRO SECTION                                   //
//****************************************************//

#hm-intro {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem;
  h2 {
    // color:  $white;
    margin: 0 auto 20px;
    text-align: center;
    font-family: $font-outfit;
    font-size: 22px;
    font-weight: 700;
    // text-transform: uppercase;
  }
  p {
    max-width: 720px;
    margin: 6px auto 0;
    text-align: center;
    font-family: $font-outfit;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
  }
}


@include media-breakpoint-up(sm) { // 576
  #hm-intro h2 {
    font-size: 24px;
  }
}
@include media-breakpoint-up(md) { // 768
  #hm-intro h2 {
    max-width: 600px;
    font-size: 24px;
    line-height: 30px;
  }
}

@include media-breakpoint-up(lg) { // 992
  #hm-intro h2 {
    max-width: 720px;
    font-size: 30px;
    line-height: 33px;
  }
  #hm-intro p {
    max-width: 920px;
  }
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600


//****************************************************//
// 3. ICON FEATURES SECTION                           //
//****************************************************//

.hm-feature {
  max-width: 300px;
  margin: 0 auto;
  border: 2px solid #f3f3f3;
}

.hm-feature h5 {
  font-family: $font-family-heading;
  max-width: 300px;
  margin: 0 auto;
  a:hover {
    text-decoration: none;
  }
}

.hf-icon {
  font-size: 78px;
}

#hfi1 h5 {
  max-width: 180px;
  margin: 27px auto !important;
}
#hfi2 h5 {
  max-width: 230px;
}
#hfi3 h5 {
  max-width: 180px;
}
#hfi4 h5 { 
  max-width: 200px;
}

.line-draw-inner::before,
.line-draw-inner::after,
.line-draw-animation::before,
.line-draw-animation::after {
  // background: #003777;
  background: $blue;
}

@media (max-width: 360px) {
  .hm-feature h5 {
    font-size: 13px;
  }
}

@media (min-width: 560px) {
  .hm-feature {
    min-height: 244px;
    h5 {
      font-size: 20px;
      line-height: 22px;
      font-weight: 800;
    }
  }
}
@include media-breakpoint-up(xl) {
  .hm-feature {
    min-height: 244px;
  }
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600

//****************************************************//
// 4. SERVICES PANELS SECTION                         //
//****************************************************//

#hm-services {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.itb-dbl-grid {
  max-width: 620px;
  margin: 0 auto;
}
.itb-image {
  margin-top: 40px;
}
.itb-text {
  padding: 20px;
  background: $bg-grey;
  h2 {
    font-size: 1.4rem;
    max-width: 620px;
    text-transform: uppercase;
  }
  p {
    font-family: $font-family-base;
    font-size: 1.05rem;
    line-height: 1.4rem;
    color:$black;
  }
  a {
    width: 136px;
    text-align: center;
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    background: $dark !important;;
    color: $white !important;;
  }
  a:hover {
    background: $blue !important;;
    color: $white !important;;
  }
}

@include media-breakpoint-up(sm) {
  .itb-text {
    min-height: 240px;
    h2 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.15rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .itb-text {
    h2 {
      font-size: 1.4rem;
    }
    p {
      font-size: 1.05rem;
    }
  }

  // .itbd {
  //   border-top: 7px solid #FFF;
  // }
  .itb-dbl-grid {
    max-width: initial;
    // margin: 0;
    // margin-top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 0 40px;
  }
 
  #itbd-img-1 {
    grid-area: 1/1/2/2;
  }
  #itbd-txt-1 {
    grid-area: 2/1/3/2;
  }
  #itbd-img-2 {
    grid-area: 1/2/2/3;
  }
  #itbd-txt-2 {
    grid-area: 2/2/3/3;
  }
  #itbd-img-3 {
    grid-area: 4/1/5/2;
  }
  #itbd-txt-3 {
    grid-area: 5/1/6/2;
  }
  #itbd-img-4 {
    grid-area: 4/2/5/3;
  }
  #itbd-txt-4 {
    grid-area: 5/2/6/3;
  }
}

@include media-breakpoint-up(xl) {
  .itb-text {
    min-height: 240px;
    h2 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.15rem;
    }
  }
  
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600

//****************************************************//
// 5. ABOUT SECTION                                   //
//****************************************************//

#hm-about {
  padding: 2rem 0 6rem;
  // nothing yet
}
.itf-grid {
  max-width: 580px;
  margin: 0 auto;
  padding-top: 20px;
}
#itf-text {
  display: flex;
  padding-bottom: 20px;
  h3 {
    font-size: 1.6rem;
    text-transform: uppercase;
  }
  p {
    padding-right: 20px;
    font-size: 1.25rem;
    line-height: 1.4rem;
    color: $black;
  }
}
@media (max-width: 400px) {
  #itf-text h3 {
    font-size: 1.2rem;
  }
}
@include media-breakpoint-up(sm) {
  #itf-text h3 {
    font-size: 1.65rem;
  }
  #itf-text p {
    padding-right: 40px;
  }
}
@include media-breakpoint-up(lg) {
  .itf-grid {
    max-width: initial;
    margin: 0 auto;
    padding: 0;
    display: grid;
    grid-template-columns: auto 540px;
    // grid-template-rows: auto 45px;
    gap: 0 40px;
  }
  #itf-text {
    grid-area: 1/1/2/2;
    align-items: flex-start;
    height: 100%;
    padding-bottom: 0;
  }
  #itf-image {
    grid-area: 1/2/2/3;
    text-align: right;
  }
}

@include media-breakpoint-up(xl) {
  .itf-grid {
    grid-template-columns: auto 730px;
  }
}
@include media-breakpoint-up(xxxl) {
  .itf-grid {
    grid-template-columns: auto 840px;
  }
  .itf-grid {
    gap: 0 60px;
  }
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600


//****************************************************//
// 6. GALLERY SECTION                                 //
//****************************************************//

#hm-gallery {
  padding-top: 4rem;
  padding-bottom: 1rem;
  a.bg-tp-white:hover {
    background: #fff;
  }
}
.hm-gallery-hover {
  width: 100%;
  position: relative;
  bottom: 0px;
  margin: 0;
  padding: 0;
}
.hm-gallery-cat h6 {
  font-size: 0.9rem;
  text-shadow: 1px 1px #444;
}

.hm-gallery-name {
  // padding-top: 0.25rem;
  // h5.card-title {
  //   margin: 0 10px 6px;
  // }
  // h5.card-title a {
  //   margin: 10px 0;
  //   padding: 0;
  //   font-size: 0.9rem;
  //   font-weight: 400;
  //   color: $white;
  //   text-transform: uppercase;
  // }
  // h5.card-title a:hover {
  //   color: $blue;
  // }

  a.btn {
    background: rgba(255, 255, 255, 0.85);
    color: $dark !important;
    border: 0;
    font-weight: 700;
    margin: 0;
    width: 100%;
  }
  a.btn:hover {
    color: $white !important;
    background: $blue; // rgba(255, 247, 236, 0.9);
  }
}

.bg-gallery-overlay {
  // background: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.6);
  .fw-normal {
    color: #777;
  }
}
.card-element-hover.bg-primary.hm-gallery-view {
  padding: 0.5rem 0.8rem !important;
  background: rgba(0, 0, 0, 0.6) !important;
}
.card-element-hover.bg-primary.hm-gallery-view:hover {
  background: $blue !important;
}

@media (max-width: 360px) {
  #tns1 > .tns-item {
    width: calc(8%) !important;
    // padding-right: 20px !important;
  }
}
@media (max-width: 480px) {
  .hm-gallery-cat {
    padding: 0.65rem;
    h6 {
      font-size: 0.75rem;
    }
  }
  .hm-gallery-view {
    display: none;
  }
  .hm-gallery-hover {
    width: auto !important;
  }
  .hm-gallery-name {
    padding: 0 !important;
    // padding: 10px !important;
    p,
    h5,
    span.hm-gallery-action {
      display: none;
    }
    a.btn {
      margin-bottom: 0;
    }
  }
  #tns1 > .tns-item {
    //    width: calc(7%) !important;
    padding-right: 20px !important;
  }
}
@media (max-width: 540px) {
  #tns1 > .tns-item {
    //    width: calc(7%) !important;
    padding-right: 20px !important;
  }
  // .hm-gallery-name {
  //   padding: 6px;
  //   p {
  //     display: none;
  //   }
  // }
}

// @include media-breakpoint-up(xl) { // 1200
//   .hm-gallery-name h5.card-title {
//       margin: 0 0 6px;
//     }
// }

@include media-breakpoint-up(xxl) { // 992 
  #hm-gallery {
    padding-top: 4rem;
    padding-bottom: 1rem;
    a.bg-tp-white:hover {
      background: #fff;
    }
  }
  // .hm-gallery-hover {
  //   width: 100%;
  //   position: relative;
  //   bottom: 0px;
  //   margin: 0;
  //   padding: 0;
  // }
  .hm-gallery-cat h6 {
    font-size: 0.94rem;
    text-shadow: 1px 1px #444;
  }
  // .hm-gallery-name {
  //   padding: 0.75rem 1rem 1rem !important;
  //   h5.card-title a {
  //     margin: 0 0 0 3px;
  //     padding: 0;
  //     text-align: left;
  //     margin: 0 0 0 3px;
  //     font-size: 1rem;
  //     font-weight: 400;
  //     color: $white;
  //     text-transform: uppercase;
  //     width: initial;
  //   }
  //   h5.card-title a:hover {
  //     color: $blue;
  //   }
  
    // a.btn {
    //   // margin-bottom: 0;
    //   // width: 100%;
    //   // background: rgba(0, 0, 0, 0.6);
    //   background: rgba(255, 255, 255, 0.85);
    //   color: $dark !important;
    //   border: 0;
    //   // text-shadow: 1px 1px #CCC;
    //   font-weight: 700;
    //   width: initial;
    // }
    // a.btn:hover {
    //   color: $white !important;
    //   background: $blue; // rgba(255, 247, 236, 0.9);
    // }
  // }
  
  // .bg-gallery-overlay {
  //   // background: rgba(255, 255, 255, 0.85);
  //   background: rgba(0, 0, 0, 0.6);
  //   .fw-normal {
  //     color: #777;
  //   }
  // }

}


/*
#hm-gallery {
  padding-top: 4rem;
  padding-bottom: 1rem;
  a.bg-tp-white:hover {
    background: #fff;
  }
}
.hm-gallery-hover {
  width: 100%;
  position: relative;
  bottom: 0px;
  margin: 0;
  padding: 0;
}
// #tns1 > .tns-item {
//   padding-right: 4px !important;
// }
  .hm-gallery-cat {
    padding: 2rem;
    h6 {
      font-weight: 700;
      font-size: 15px;
      text-shadow: 1px 1px #444;
      // font-size: 0.75rem;
    }
  }

.hm-gallery-name {
  padding: 0 !important;
  a.btn {
    margin-bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    // background: #fff;
    color: $white !important;
    border: 0;
    // border-left: none;
    // border-right: none;
    // border-top: 1px solid $black;
    // border-bottom: 1px solid $black;
    text-shadow: 1px 1px #444;
    font-weight: 700;
    // border: 2px solid #fff;
  }
  a.btn:hover {
    color: $white !important;
    // border-top: 1px solid  $blue;
    // border-bottom: 1px solid  $blue;
    background: $blue; // rgba(255, 247, 236, 0.9);
    // border-top: 1px solid $blue;
    // border-bottom: 1px solid $blue;
    // border: 2px solid  $blue;
  }
}
.tns-controls [data-controls]:hover {
  background:  $blue;
}
/*
@media (max-width: 360px) {
  #tns1 > .tns-item {
    width: calc(8%) !important;
    // padding-right: 20px !important;
  }
}
@media (max-width: 480px) {
  .hm-gallery-cat {
    padding: 0.65rem;
    h6 {
      font-size: 0.75rem;
    }
  }
  .hm-gallery-view {
    display: none;
  }
  .hm-gallery-hover {
    width: auto !important;
  }
  .hm-gallery-name {
    padding: 10px !important;
    span.hm-gallery-action {
      display: none;
    }
    a.btn {
      margin-bottom: 0;
    }
  }
}
@media (max-width: 540px) {
  .hm-gallery-name {
    padding: 6px;
    p {
      display: none;
    }
  }
}
*/


// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxl) { // 1400
// @include media-breakpoint-up(xxxl) { // 1600



//****************************************************//
// 7. TESTIMONIALS SECTION                            //
//****************************************************//

#hm-testimonials {
  padding-top: 2rem;
  padding-bottom: 0;
  h6 {
    color: $dark-grey !important;
    font-size: 1.1rem;
    font-weight: 700;
    // font-weight: 400;
    // font-style: italic;
  }
  .lead {
    font-size: 1.05rem;
    line-height: 1.6rem;
    // font-style: italic;
    // color:  $blue;
  }
  // .tns-nav {
  //   button,
  //   button:after {
  //     -webkit-box-shadow: inset 0 0 0 2px  $blue;
  //     box-shadow: inset 0 0 0 2px  $blue;
  //   }
  //   button:after {
  //     background-color:  $blue;
  //   }
  // }
  .tiny-slider-inner .item p {
    max-width: 240px;
    text-align: center;
    margin: 0 auto;
  }
}

// #hm-testimonials-ts > div.tns-slide-active {
//   opacity: 50%;
// }
// #hm2-testimonials-ts div.tns-slide-active:nth-of-type(2) {
//   opacity: 100%;
// }

#tns2 > .tns-item {
  font-size: 1rem;
  // width: calc(8%) !important;
  // padding-right: 20px !important;
}


// @media (min-width: 460px) {
//   #hm-testimonials .tiny-slider-inner .item p {
//     max-width: 320px;
//   }
// }

@include media-breakpoint-up(sm) { // 576
  #hm-testimonials .tiny-slider-inner .item p {
    max-width: 300px;
  }
}

@include media-breakpoint-up(md) { // 768
  #hm-testimonials {
    h6 {
      font-size: 1.2rem;
    }
    .lead {
      font-size: 1.15rem;
    }
  }
  #hm-testimonials .tiny-slider-inner .item p {
    max-width: 760px;
  }
}

@include media-breakpoint-up(lg) {
  #hm-testimonials {
    h6 {
      font-size: 1.3rem;
    }
    .lead {
      font-size: 1.25rem;
    }
  }
  #hm-testimonials-ts .tns-item {
    opacity: 50%;
  }
  #hm-testimonials-ts
    .tns-item:not(.tns-slide-active)
    + .tns-slide-active
    + .tns-slide-active {
    opacity: 100%;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}

// @media (max-width: 480px) {}
// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600


//****************************************************//

//****************************************************//
// FOOTER CONTENT START                               //
//****************************************************//

//****************************************************//
// 1. FOOTER LOGO BRANDS SECTION                      //
//****************************************************//

#footer-logos {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

#footer-logos-col {
  margin-bottom: 30px;
}
.footer-logos-sml {
  padding: 24px 0;
}
#footer-logos-images {
  // padding-left: 2.5rem;
  width: 320px;
  img {
    display: block;
    margin: 0 auto 30px;
  }
  img:last-child {
    margin: 0 auto;
  }
}
.footer-logos-img {
  max-height: initial;
  width: initial;
  padding: 0 20px;
}
#footer-logos-last {
  padding: 0 0 0 20px !important;
}

@include media-breakpoint-up(sm) { // 576
  #footer-logos-images {
    max-width: 700px;
  }
  #footer-logos-images div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #footer-logos-col {
    margin-bottom: 0;
  }
  #footer-logos {
    img {
      display: inline;
      margin: 0 auto;
    }
  }
}

@include media-breakpoint-up(md) { // 768
  // #footer-logos-images {
  //   max-width: 700px;
  // }
  #footer-logos-images div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #footer-logos-col {
    margin-bottom: 0;
  }
  #footer-logos {
    img {
      display: inline;
      margin: 0 auto;
    }
  }
}

// @include media-breakpoint-up(lg) {
//   #footer-logos-images {
//     max-width: 700px;
//   }
// }

@include media-breakpoint-up(xxl) {
  #footer-logos-images {
    max-width: initial;
    padding: 0;
  }
  #footer-logos-first {
    padding: 0 20px 0 0 !important;
  }
  #footer-logos-last {
    padding: 0 0 0 20px !important;
  }  
}
// 1b. FOOTER WELLINGTON FREE AMBULANCE SECTION //
/*#footer-wfa {
  padding: 0;
}

#footer-wfa-col {
  margin-bottom: 0;
  padding: 0;
}
.footer-wfa-img {
  max-height: initial;
  width: initial;
}
@include media-breakpoint-up(md) {
  #footer-wfa {
    padding: 0 1rem;
  }
}*/

//****************************************************//
// 2. FOOTER LINKS SECTION                            //
//****************************************************//

#footer-content .bg-blue-gradient {
  padding-top: 27px !important;
}

// #footer-content .container {
//   max-width: 90%;
// }
#footer-content-links {
  // max-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0; // row-gap column-gap
}

// #footer-brand {
//   // padding-right: 40px;
// }
.footer-sticky .navbar-brand-item {
  height: 63px;
  // margin-right: 80px;
}

.footer-links i {
  padding-right: 9px;
}
.footer-links h5 {
  margin: 10px 0 0;
  color:  $white;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.5px;
  font-weight: 800;
  text-transform: uppercase;
}
.footer-links li.nav-item {
  font-size: 16px;
  line-height: 26px;
  margin: 0px 0;
  // max-width: 150px;
  color: $light-grey;
  a.nav-link {
    font-weight: 400;
    color: $light-grey !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  a.nav-link:hover {
    color: $white !important;
  }
}
.footer-links li.nav-no-link {
  margin: 0;
}
.footer-links li.nav-no-link:hover {
  color: $white !important;
}
.nav-social {
  // font-size: 30px;
  // line-height: 30px;
  margin-right: 2px;
}

#footer-copyright li a:hover {
  color: $white !important;
  text-decoration: none;
}

#footer-copyright {
  p, li, 
  li a {
    color: $grey-400 !important;
    font-size: 0.75rem;
  }
}
#footer-copyright li {
  padding: 0 1.5rem 0 0;
}
#footer-copyright li a.nav-link {
  padding: 0 !important;
}
#footer-sop a img {
  padding-left: 10px;
}
#footer-copyright li#footer-sop a:hover {
  color: #fff !important;
}


@include media-breakpoint-up(sm) {
  #footer-content-cells {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 0 40px; // row-gap column-gap
  }
  #fl-1 {
    grid-area: 2/1/3/2;
    width: 120px;
  }
  #fl-2 {
    grid-area: 2/2/3/3;
    width: 190px;
  }
  #fl-3 {
    grid-area: 3/1/4/2;
    width: 120px;
  }
  #fl-4 {
    grid-area: 3/2/4/3;
    width: 170px;
  }
}


@include media-breakpoint-up(md) { // 768
  #footer-content-cells {
    grid-template-columns: 1fr 1fr 1fr;
    width: 700px;
    gap: 0 80px;
  }
  #fl-1 {
    grid-area: 1/2/2/3;
  }
  #fl-2 {
    grid-area: 2/1/3/2;
  }
  #fl-3 {
    grid-area: 2/2/3/3;
  }
  #fl-4 {
    grid-area: 2/3/3/4;
  }
}

@include media-breakpoint-up(lg) { // 992
  #footer-content-cells {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 900px;
    gap: 0 40px;
  }
  #fl-1 {
    grid-area:unset;
    width: 95px;
  }
  #fl-2 {
    grid-area:unset;
  }
  #fl-3 {
    grid-area:unset;
  }
  #fl-4 {
    grid-area:unset;
  }

}

@include media-breakpoint-up(xl) { // 1200
  #footer-content-cells {
    width: 1100px;
    display: flex;
  }
  // #footer-brand {
  //   padding-left: 0;
  //   padding-right: 0;
  // }  
  // .footer-links {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
}

@include media-breakpoint-up(xxl) { // 1600
  #footer-content-cells {
    width: 1240px;
  }
}

@include media-breakpoint-down(md) {
  #footer-content-links {
    margin: 0 auto;
  }
  #footer-copylinks {
    display: block !important;
    margin: 0 auto;
  }
}

// .footer-sticky .navbar-brand-item {
//   height: 30px;
// }
// @media (min-width: 410px) {
//   .footer-sticky .navbar-brand-item {
//     height: 40px;
//   }
// }
// @include media-breakpoint-up(xl) {
//   .footer-sticky .navbar-brand-item {
//     height: 58px;
//   }
// }

// @media (max-width: 480px) {}
// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxl) { // 1600


// **************** INNER PAGES **************** //

// A. CASE STUDIES //

// .case-study-element {
//   text-align: left;
//   line-height: 36px;
// }

// @media (max-width: 500px) {
//   h5.case-study-element span.bg-primary {
//     background: none !important;
//     color: $rcl-blue;
//     padding: 0 !important;
//   }
// }

// @include media-breakpoint-up(lg) {
//   .case-study-element {
//     text-align: right;
//   }
// }
